import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { changeProyects } from "../redux/userSlice.js"
import axios from "axios"
import MeetingMini from "./MeetingMini"
import swal from "sweetalert"
import { useTranslation } from "react-i18next"
function ProyectManage() {
    const { t } = useTranslation(["trProjectManage"]);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const data = location.state
    const [meetings, setMeetings] = useState([])
    const [selectedMeeting, setSelectedMeeting] = useState(null)
    const URL_API = process.env.REACT_APP_API_URL

    useEffect(() => {
        axios.post(`${URL_API}/api/getMeetingList`, { data }, {
            withCredentials: true
        })
            .then((res) => {
                setMeetings(res.data.data.meetings)
                setSelectedMeeting(null)
            })
    }, [data])
    const renderSelected = (event) => {
        setSelectedMeeting(event.target.value)

    }
    const editMeetingHandler = (event) => {
        let lang=localStorage.getItem("i18nextLng")
        axios.post(`${URL_API}/api/getmeeting`, { meetingId: event.target.getAttribute("name"),lang }, {
            withCredentials: true
        })
            .then((res) => {
                console.log(res)
                if (res.data.alert) {
                    swal(res.data.alert)
                } else {
                    navigate("/meetingedit", { state: { meetid: res.data.data } })
                }
            })

    }
    const handleNewMeeting = () => {
        let lang=localStorage.getItem("i18nextLng")
        axios.post(`${URL_API}/api/checkproyectowner`, { proyId: data.param2,lang }, {
            withCredentials: true
        })
            .then((res) => {
                if (res.data.alert) {
                    swal(res.data.alert)
                } else {
                    navigate("/NewProy", { state: { proyName: data.param1 } })
                }
            })
    }
    const handleCloseProyect = () => {
        let lang=localStorage.getItem("i18nextLng")
        axios.post(`${URL_API}/api/closeproyect`, { proyId: data.param2,lang }, {
            withCredentials: true
        })
            .then((res) => {
                if (res.data.alert) {
                    swal(res.data.alert)
                } else {
                    axios.get(`${URL_API}/api/getproyectlist`, {
                        withCredentials: true
                    }).then((res) => {
                        if (res.data.data) {

                            dispatch(changeProyects(res.data.data))

                        }
                    })
                    navigate("/allmyproyects")
                }
            })
    }
    const handleDeleteMeeting = () => {
        let lang=localStorage.getItem("i18nextLng")
        axios.post(`${URL_API}/api/deleteMeeting`, { proyId: meetings[selectedMeeting].proyId._id,lang }, { withCredentials: true })
            .then((res) => {
                if (res.data.alert) {
                    swal(res.data.alert)
                } else {
                    axios.post(`${URL_API}/api/getMeetingList`, { data }, {
                        withCredentials: true
                    })
                        .then((res) => {
                            setMeetings(res.data.data.meetings)
                            setSelectedMeeting(null)
                        })
                }
            })
    }

    return (
        <div className="container-fluid formPagesBackgroundPattern">
            <div className="row py-5">
                <div className="col-4">
                    <div className="row">
                        <h3><p>{data.param1}</p></h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2">

                    <div className="row">
                        <div className="col">
                            {/* <Link to={"/NewProy"} state={{ proyName: data.param1 }}> <button type="button" className="btn btn-primary myBtn">Nueva Reunion</button> <br /><br /></Link> */}
                            <button type="button" className="btn btn-primary myBtn" onClick={handleNewMeeting}>{t("btnNewMeeting")}</button> <br /><br />
                            <button type="button" className="btn btn-primary myBtn" onClick={handleCloseProyect}>{t("btnClose Project")}</button>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="row">
                        <p>{t("pMeetingList")}</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="row">
                            <div className="col-12">
                                <select className="form-select" size="10" onChange={renderSelected}>
                                    {
                                        meetings.map((e, index) => (
                                            <option value={index} key={index} style={{ fontSize: "small" }} name={e.proyId._id} onDoubleClick={editMeetingHandler}>{e.proyId.dbName}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                {selectedMeeting ? <button className="btn btn-primary myBtn" onClick={handleDeleteMeeting}>Borrar</button> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    {selectedMeeting ?
                        <MeetingMini meeting={meetings[selectedMeeting]} />
                        : <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProyectManage
import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import swal from "sweetalert"
import axios from "axios"
import Overlay from "react-bootstrap/Overlay"
import Tooltip from "react-bootstrap/Tooltip"
import { useTranslation } from "react-i18next"
function CreateAccount() {
    const { t} = useTranslation(["trCreateAccount"]);
    const navigate = useNavigate()
    const URL_API = process.env.REACT_APP_API_URL
    const URL = `${URL_API}/userauth`
    const [password, setPassword] = useState("")
    const [validate, setValidate] = useState(true)
    const [warning, setWarning] = useState(false)
    const [warning2, setWarning2] = useState(false)
    const [email, setEmail] = useState("")
    const [show, setShow] = useState(false)
    const target=useRef(null)

    const styleWarning = (e) => {
        if (password === e.target.value) {
            setWarning(false)
            setValidate(false)
        } else {
            setWarning(true)
            setValidate(true)
        }
    }

    const handleRegister = () => {
        const data = {
            email,
            password
        }
        axios.post(URL, data, { withCredentials: true })
            .then((res) => {
                if(res.data.error){
                    setShow(true)
                    setWarning2(true)
                }
                else{
                    setShow(false)
                    setWarning2(false)
                    swal("Se ha enviado un codigo de validacion a su direccion de email")
                    .then(()=>{navigate("/validator", { state: { email:email} })})
                }
                console.log(res)
            })
    }
    return (
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-4 border border-3 shadow">
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            <h3>meetInOrder</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            {t("createAccount")}
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            <input ref={target} type="email" className="form-control" style={warning2 ? { borderColor: "red" } : null} id="exampleFormControlInput1" placeholder={t("phEmail")} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col">
                            <input type="password" className="form-control" id="exampleFormControlInput2" placeholder={t("phPassword")} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            <input type="password" className="form-control" style={warning ? { borderColor: "red" } : null} id="repeatPassword" placeholder={t("phPassword2")} onChange={styleWarning} />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            <button type="button" className="btn btn-primary myBtn" disabled={validate} onClick={handleRegister}>{t("Register")}</button>
                        </div>
                    </div>
                    <div className="row mt-2 px-5">
                        <hr />
                    </div>
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            <a className="nav-link active" aria-current="page" href="/login">{t("login")}</a>
                        </div>
                    </div>
                </div>
            </div>
            <Overlay target={target.current} show={show} placement="top">
                {(props) => (
                    <Tooltip id="OverlayEx" {...props}>
                        {t("overlay")}
                    </Tooltip>
                )}

            </Overlay>
        </div>
    )
}

export default CreateAccount
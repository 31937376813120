import { useState } from "react"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
function TaskList() {
    const {t} = useTranslation(["trTaskList"]);
    const location = useLocation()
    const [addTask, setAddTask] = useState(true)
    const [updateBtn, setUpdateBtn] = useState(false)
    const [deleteBtn, setDeleteBtn] = useState(false)
    const [addBtn, setAddBtn] = useState(true)
    const [taskList, setTaskList] = useState([])
    const data = location.state
    const cleanForm = () => {
        document.getElementById("inputNameTask").value = ""
        document.getElementById("inputDescTask").value = ""
        document.getElementById("inputResponsibleTask").value = ""
        document.getElementById("inputDeadlineTask").value = ""
        document.getElementById("itemListIndex").value = ""
        setAddTask(true)
        setAddBtn(true)
        setDeleteBtn(false)
        setUpdateBtn(false)
    }
    const newTaskBtn = () => {
        cleanForm()
        setAddTask(true)
        setAddBtn(true)
        setDeleteBtn(false)
        setUpdateBtn(false)
    }
    const addTaskBtn = () => {
        let taskName = document.getElementById("inputNameTask").value
        let taskDesc = document.getElementById("inputDescTask").value
        let taskResp = document.getElementById("inputResponsibleTask").value
        let taskDeadLine = document.getElementById("inputDeadlineTask").value
        let obj = { taskName, taskDesc, taskResp, taskDeadLine }
        let newTaskList = [...taskList]
        newTaskList.push(obj)
        setTaskList(newTaskList)
        cleanForm()
    }

    const editTask = (index) => {
        if (index) {
            let editTaskitem = taskList[index]
            document.getElementById("inputNameTask").value = editTaskitem.taskName
            document.getElementById("inputDescTask").value = editTaskitem.taskDesc
            document.getElementById("inputResponsibleTask").value = editTaskitem.taskResp
            document.getElementById("inputDeadlineTask").value = editTaskitem.taskDeadLine
            document.getElementById("itemListIndex").value = index
            setAddTask(true)
            setDeleteBtn(true)
            setUpdateBtn(true)
            setAddBtn(false)
        }
    }

    const deleteTask = () => {
        let index = document.getElementById("itemListIndex").value
        let deleteTaskItem = [...taskList]
        deleteTaskItem.splice(index, 1)
        setTaskList(deleteTaskItem)
        cleanForm()
    }

    const updateTask = () => {
        let index = document.getElementById("itemListIndex").value
        let updateTaskList = [...taskList]
        let taskName = document.getElementById("inputNameTask").value
        let taskDesc = document.getElementById("inputDescTask").value
        let taskResp = document.getElementById("inputResponsibleTask").value
        let taskDeadLine = document.getElementById("inputDeadlineTask").value
        let obj = { taskName, taskDesc, taskResp, taskDeadLine }
        updateTaskList.splice(index, 1)
        updateTaskList.push(obj)
        setTaskList(updateTaskList)
        cleanForm()
    }

    return (
        <div className="container-fluid formPagesBackgroundPattern">
            <div className="row justify-content-center py-5">
                <div className="col-auto">
                    <span><h2>{data.proyectName}</h2></span>
                </div>
            </div>
            <div className="row justify-content-evenly">
                <div className="row justify-content-evenly">
                    <div className="col-auto">
                        <div className="row">
                            <div className="col-auto">
                                <button type="button" className="btn btn-primary mb-3 myBtn" onClick={() => newTaskBtn()}>{t("btnNewTask")}</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <div className="card" style={{ width: "26rem" }}>
                                    <select className="list-group list-group-flush" multiple id="taskListDropdown" onInput={(e) => editTask(e.target.value)} onDoubleClick={(e) => editTask(e.target.value)}>
                                        {taskList.map((e, index) => (
                                            <option value={index} key={index} className="list-group-item">{e.taskName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto NameProjectList" style={addTask ? { visibility: "visible" } : { visibility: "hidden" }}>
                        <div className="row m-2">
                            <label htmlFor="inputNameTask" className="visually-hidden">TaskName</label>
                            <input type="text" className="htmlForm-control" id="inputNameTask" placeholder={t("phTaskName")} />
                            <input type="text" id="itemListIndex" readOnly value={undefined} style={{ visibility: "hidden" }} />
                        </div>
                        <div className="row m-2">
                            <label htmlFor="inputDescTask" className="visually-hidden">TaskDesc</label>
                            <textarea type="textArea" rows={10} cols={40} className="htmlForm-control" id="inputDescTask" placeholder={t("phTaskDescription")} />
                        </div>
                        <div className="row m-2">
                            <label htmlFor="inputResponsibleTask" className="visually-hidden">TaskResponsable</label>
                            <input type="text" className="htmlForm-control" id="inputResponsibleTask" placeholder={t("phResponsable")} />
                        </div>
                        <div className="row m-2">
                            <label htmlFor="inputDeadlineTask" className="visually-hidden">TaskDeadline</label>
                            <input type="text" className="htmlForm-control" id="inputDeadlineTask" placeholder={t("phDeadLine")} />
                        </div>

                        <div className="row m-2">
                            <button type="button" className="btn btn-primary mb-3" style={addBtn ? { visibility: "visible" } : { visibility: "hidden" }} onClick={() => addTaskBtn()}>{t("btnAdd")}</button>
                            <button type="button" className="btn btn-primary mb-3" style={updateBtn ? { visibility: "visible" } : { visibility: "hidden" }} onClick={() => updateTask()}>{t("btnUpdate")}</button>
                            <button type="button" className="btn btn-primary mb-3" style={deleteBtn ? { visibility: "visible" } : { visibility: "hidden" }} onClick={() => deleteTask()}>{t("btnDelete")}</button>
                        </div>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-12">
                        <Link to={"/meetingsummary"} state={{ data, taskList }}><button type="button" className="btn btn-primary mb-3 myBtn">{t("btnFinish")}</button></Link>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TaskList



import GuiaDeUso from "./GuiaDeUso";
import NewProject from "./NewProject";

function LandingPage() {
    return (
        <>
            <NewProject />
            <GuiaDeUso />
        </>
    )
}

export default LandingPage
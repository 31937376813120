import { Link } from "react-router-dom"
import vidNewProject from "@images/vidNewProjectGuide.gif"
import vidNewProject2 from "@images/vidNewMeetingGuide.gif"
import vidNewProject3 from "@images/vidNewEditionPage.gif"
function GuiaDeUso() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <h2>Guia De Uso</h2>
                </div>
            </div>
            <div className="row">
                {/* <div className="col-6">
                    <h3>1.Crea tu primer proyecto</h3>
                    <p>Inicia creando tu primer proyecto, una vez hayas creado tu cuenta podras acceder a las opciones de nuevo proyecto, donde podras guardar y mantener un historico de cada reunion de cada proyecto y consultarlo en cualquier momento</p>
                    <p>Podras crear tu proyecto desde la pagina principal</p>
                    <p>O</p>
                    <p>En el menu mis proyectos</p>
                </div> */}
                <div className="col-12">
                    <div className="row">
                        <div className="col-1">
                            <button type="button" className="btn btn-primary myCarouselBtn" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev" data-bs-theme="dark">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                        </div>
                        <div className="col-10">
                            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active" data-bs-interval="100000">
                                        <div className="row">
                                            <div className="col">
                                                <h3>1.Crea tu primer proyecto</h3>
                                                <p>Inicia creando tu primer proyecto, una vez hayas creado tu cuenta podras acceder a las opciones de nuevo proyecto, donde podras guardar y mantener un historico de cada reunion de cada proyecto y consultarlo en cualquier momento</p>
                                                <p>Podras crear tu proyecto desde la pagina principal</p>
                                                <p>O</p>
                                                <p>En el menu mis proyectos</p>
                                            </div>
                                            <div className="col">
                                                <img src={vidNewProject} className="d-block w-100" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-bs-interval="100000">
                                        <div className="row">
                                            <div className="col">
                                                <h3>2.Crea tu primera reunion</h3>
                                                <p>Abre tu proyecto y crea tu primera reunion</p>
                                                <p>Completa la informacion con los datos de la reunion, participantes, tareas/decisiones, con esta informacion generaremos el informe de la reunion para descargar</p>
                                            </div>
                                            <div className="col">
                                                <img src={vidNewProject2} className="d-block w-100" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-bs-interval="100000">
                                        <div className="row">
                                            <div className="col">
                                                <h3>3.Verifica la informacion</h3>
                                                <p>Te falto agregar alguna tarea o algun participante?, no te preocupes, en la etapa de edicion puedes modificar o agregar cualquier informacion que necesites.</p>
                                                <p>Asi como reordenar las tareas, para que en tu informe esten ordenadas como tu lo necesitas.</p>
                                                <p>Tambien puedes agregar un parrafo inicial en el informe, en el que puedas describir o informar algun dato adicional de la reunion</p>
                                            </div>
                                            <div className="col">
                                                <img src={vidNewProject3} className="d-block w-100" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1">
                            <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next" data-bs-theme="dark" className="btn btn-primary myCarouselBtn">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                   <Link to={"/allguides"}><button type="button" className="btn btn-primary myBtn">Mira Todas nuestras guias</button></Link>
                </div>
            </div>
        </div>
    )
}

export default GuiaDeUso
import './App.css';
import { BrowserRouter, Route, Routes} from "react-router-dom"
import NavigBar from './components/NavigBar';
import NameProject from './components/NameProject';
import TaskList from "./components/TaskList.jsx"
import MeetSumDnD from './components/MeetSumDnD.jsx';
import ProyectHistory from './components/ProyectHistory.jsx';
import ProyectManage from './components/ProyectManage.jsx';
import EditMeetingPage from './components/EditMeetingPage.jsx';
import AllProyects from './components/AllProyects.jsx';
import Login from './components/Login.jsx';
import CreateAccount from './components/CreateAccount.jsx';
import Validator from './components/Validator.jsx';
import LandingPage from './components/LandingPage.jsx';
import AllGuides from './components/AllGuides.jsx';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <NavigBar />
        <ProyectHistory />
        <Routes>
          <Route path={'/'} element={<LandingPage />} />
          <Route path={'/newProy'} element={<NameProject />}/>
          <Route path={'/taskList'} element={<TaskList />}/>
          <Route path={'/meetingsummary'} element={<MeetSumDnD/>}/>
          <Route path={'/proyectmanager'} element={<ProyectManage/>}/>
          <Route path={'/meetingedit'} element={<EditMeetingPage/>}/>
          <Route path={'/allmyproyects'} element={<AllProyects/>}/>
          <Route path={'/login'} element={<Login/>}/>
          <Route path={'/newaccount'} element={<CreateAccount/>}/>
          <Route path={'/validator'} element={<Validator/>}/>
          <Route path={'/allguides'} element={<AllGuides/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

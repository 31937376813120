function AllGuides() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    Guia de Usuario
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Nuevo Proyecto
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Debes crear un cuenta para poder guardar y tener el historico de tus proyectos y tus reuniones.</p>
                                    <p>Puedes crear un nuevo proyecto desde el menu mis proyectos Crear nuevo proyecto, o desde la pagina principal "Inicia tu nuevo proyecto"</p>
                                    <p>Completa la informacion del nombre del proyecto y el cliente</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Nueva Reunion
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Una vez crees tu nuevo proyecto, estaras en el administrador de proyectos, donde podras abrir tu proyecto y crear una nueva reunion, o puedes ingresar al administrador de proyectos desde el menu mis proyectos, ver todos</p>
                                    <p>Una vez hayas abierto el proyecto, crea tu nueva reunion</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Editar la reunion
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Una vez hayas completado la informacion de la reunion, antes de generar el informe, puedes editar cualquier informacion o agregar nueva.</p>
                                    <p>Puedes hacer doble click sobre las tareas, o sobre los participantes para editarlos o eliminarlos, o usar nuestro menu para agregar nuevos, tambien puedes arrastrar las tareas para ordenarlas como tu quieras</p>
                                    <p>Tendras la opcion de generar el informe sin guardarlo, o generarlo y guardarlo, los informes almacenados pueden ser editados despues.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Permisos y Accesos
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Tu eres el dueño de tus proyectos, si tienes un equipo de trabajo, puedes asignar accesos como partipantes y como dueños.</p>
                                    <p>Un participante tendra acceso de lectura al proyecto y a sus reuniones, no podra crear reuniones nuevas, ni modificar ninguna reunion creada</p>
                                    <p>Puedes agregar un nuevo dueño, el cual tendra los mismos accesos que tu, podra crear nuevas reuniones, editar reuniones anteriores, añadir o eliminar particpantes y dueños a este proyecto</p>
                                    <p>Puedes agregar participantes y dueños con su correo electronico, tendran que crear una cuenta para acceder</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-9">

                </div>
            </div>
        </div>
    )
}

export default AllGuides
import { Link } from "react-router-dom"
import { useCookies } from "react-cookie"
import { useTranslation } from "react-i18next"


function NewProject() {
    const [cookies,] = useCookies([`token`])
    const { t } = useTranslation(["trBanner"]);
    return (

        <div className="container-fluid" id="homePageBanner" style={{position:"relative"}}>
            <div className="row text-start m" style={{ width: "100%" }}>
                <div className="col-sm-7 gap-2 ps-5">
                    <div className="row">
                        <div className="col-12" style={{ marginTop: "5%" }}>
                            <p style={{ color: "darkBlue", fontSize: "3rem", fontWeight: "800" }}>MeetInOrder</p>
                            <p style={{ color: "white", fontSize: "1.5rem", fontWeight: "600" }}>{t("p1")}</p>
                            <p style={{ color: "white", fontSize: "1.5rem", fontWeight: "600" }}>{t("p2")}</p>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p style={{ color: "white", fontSize: "1.1rem", fontWeight: "500" }}>{t("p3")}</p>
                            <p style={{ color: "white", fontSize: "1.1rem", fontWeight: "500" }}>{t("p4")}</p>
                            <p style={{ color: "white", fontSize: "1.1rem", fontWeight: "500" }}>{t("p5")}</p>
                            <p style={{ color: "white", fontSize: "1.1rem", fontWeight: "500" }}>{t("p6")}</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 pt-5" >
                    <div className="row d-flex justify-content-center align-items-end" style={{ width: "100%", height: "100%", paddingLeft: "-5%" }}>
                        <div className="col-sm-4">
                            {cookies.token ? <button type="button" className="btn btn-primary myBtn" id="addProyectModalBtn" data-bs-toggle="modal" data-bs-target="#modAddProyect" style={{position:"absolute",zIndex:"100"}}>{t("b1.1")}</button>
                                :
                                <div>
                                    <Link to={"/NewProy"} id="startLink"><button type="button" className="btn btn-primary myBtn">{t("b1")}</button></Link>
                                    <p style={{ color: "white", fontSize: "1.1rem", fontWeight: "400" }} className="mt-2">{t("p7")}</p>
                                    <Link to={"/login"} id="startLink"><button type="button" className="btn btn-primary myBtn" style={{position:"absolute",zIndex:"100"}}>{t("b2")}</button></Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-end" style={{width:"100%",position:"absolute",bottom:"0"}}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{width:"100%",margin:"0",padding:"0"}} viewBox="0 0 1440 320">
                    <path fill="#ffffff" fill-opacity="1" d="M0,96L48,117.3C96,139,192,181,288,218.7C384,256,480,288,576,282.7C672,277,768,235,864,234.7C960,235,1056,277,1152,282.7C1248,288,1344,256,1392,240L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                </svg>
            </div>

        </div>

    )
}

export default NewProject
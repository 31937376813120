import { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
function NameProject() {
    const { t} = useTranslation(["trNewProject"]);
    const location=useLocation()
    const data=location.state
    const [proyName,setProyName]=useState(data?data.proyName:"")
    const [participantList, setParticipantList] = useState([])
    const [meetingName, setMeetingName] = useState("")
    const addParticipant = () => {
        let partName = document.getElementById("inputName").value
        let partEmail = document.getElementById("inputEmail").value
        let partRole = document.getElementById("inputRole").value
        let obj = { partName, partEmail, partRole }
        setParticipantList(participantList => [...participantList, obj])
        document.getElementById("inputName").value = ""
        document.getElementById("inputEmail").value = ""
        document.getElementById("inputRole").value = ""
    }
    const deleteParticipant = (index) => {

        let deletePartName = [...participantList]
        deletePartName.splice(index, 1)

        setParticipantList(deletePartName)
    }
    return (
        <div className="container-fluid justify-content-center align-items-center formPagesBackgroundPattern">
            {/* agregar en algun lado el copyright del patron de fondo  <a href="https://www.freepik.com/free-photo/light-gray-concrete-wall_4139268.htm">Image by rawpixel.com</a> on Freepik */}
            <div className="row justify-content-center align-items-center py-5">
                <div className="col-auto">
                    <div className="card formStyles">
                        <div className="card-body">
                            <h5 className="card-title">{t("title")}</h5>
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-diamond-fill" viewBox="0 0 16 16">
                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zM5.495 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927" />
                            </svg></span>
                            <div className="row my-2">
                            {data?
                                <div className="col-auto">
                                    <input class="form-control" type="text" value={proyName} aria-label="readonly input example" readonly></input>
                                </div>
                                :<div className="col-auto">
                                    <label htmlFor="inputProjectName" className="visually-hidden">{t("phNameProject")}</label>
                                    <input type="text" className="htmlForm-control" id="inputProjectName" placeholder={t("phNameProject")} onChange={(e) => (setProyName(e.target.value))} />
                                </div>
                                }
                            </div>
                            <div className="row my-2">
                                <div className="col-auto">
                                    <label htmlFor="inputMeetingName" className="visually-hidden">Email</label>
                                    <input type="text" className="htmlForm-control" id="inputMeetingName" placeholder={t("phMeetingName")} onChange={(e) => (setMeetingName(e.target.value))} />
                                </div>
                            </div>
                            <form className="row g-3">

                                <div className="col-auto">
                                    <label htmlFor="inputName" className="visually-hidden">Nombre</label>
                                    <input type="text" className="htmlForm-control" id="inputName" placeholder={t("phParticipant")} />
                                </div>
                                <div className="col-auto">
                                    <label htmlFor="inputName" className="visually-hidden">Nombre</label>
                                    <input type="text" className="htmlForm-control" id="inputRole" placeholder={t("phRole")} />
                                </div>
                                <div className="col-auto">
                                    <label htmlFor="inputEmail" className="visually-hidden">Email</label>
                                    <input type="text" className="htmlForm-control" id="inputEmail" placeholder={t("phEmail")} />
                                </div>
                                <div className="col-auto">
                                    <button type="button" className="btn btn-primary mb-3" onClick={addParticipant}>{t("btnAdd")}</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row justify-content-center align-items-center">
                <div className="col-auto">
                    <div className="card" style={{ width: "18rem" }}>
                        <ul className="list-group list-group-flush">
                            {participantList.map((e, index) => (
                                <li className="list-group-item NameProjectList" key={index}>{e.partName}<button type="button" class="btn btn-outline-danger btn-sm ms-3" id={index} value={index} onClick={(e) => deleteParticipant(e.target.value)}>{t("btnDelete")}</button></li>
                            ))}

                        </ul>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center align-items-center my-5">
                <div className="col-auto">
                    <div className="col-auto">
                        <Link to={"/taskList"} state={{ meetingName, participantList,proyName }}> <button type="submit" className="btn btn-primary mb-3 myBtn">{t("btnNext")}</button></Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default NameProject
import { useTranslation } from "react-i18next"
import enIco from "../resources/images/en.png"
import esIco from "../resources/images/es.png"


const LanguageSelector = () => {
    const { i18n } = useTranslation()
    const handleLanguage = (lng) => {
        i18n.changeLanguage(lng)
    }
    return (
        <div class="d-grid d-md-flex justify-content-md-end">
            <button type="button" className="btn btn-sm " onClick={() => handleLanguage("en")}><img src={enIco} className="rounded mx-auto languageIco" alt="..." /></button>
            <button type="button" className="btn btn-sm " onClick={() => handleLanguage("es")}><img src={esIco} className="rounded mx-auto languageIco" alt="..." /></button>
        </div>


    )
}

export default LanguageSelector